import { reactive, toRefs, defineAsyncComponent } from "vue";
import { useStore } from "vuex";
import { useDebounce } from "@/hooks/core/useDebounce";
import { ElMessageBox } from "element-plus";
import API from "@/api/authority";
import { useMessage } from "@/hooks/web/useMessage";
import dayjs from "dayjs";
import { Search } from "@element-plus/icons-vue";
export default {
  name: "OrderManage",
  components: {
    // 自定义组件
    BasicTemplate: defineAsyncComponent(() => import("@/components/BasicTemplateTablePage/index.vue")),
    TypeSelect: defineAsyncComponent(() => import("@/components/TypeSelect/TypeSelect.vue")),
    TreeSelect: defineAsyncComponent(() => import("@/components/TreeSelect/TreeSelect.vue")),
    ImportModal: defineAsyncComponent(() => import("@/components/ImportModal/ImportModal.vue")),
    PromptBox: defineAsyncComponent(() => import("@/components/PromptBox/index.vue")),
    BingImei: defineAsyncComponent(() => import("./components/BingImei.vue"))
  },
  setup() {
    const store = useStore();
    const message = useMessage();
    const refData = reactive({
      refBingImei: null,
      importType: true,
      promptBoxRefs: null,
      importShow: false,
      loading: false,
      tableLoading: false,
      total: 0,
      current: 1,
      pageSize: 50,
      searchForm: {
        schoolId: '',
        deptId: '',
        parentPhone: "",
        payFlag: 0,
        imei: '',
        orderNo: '',
        alarmTime: [dayjs(new Date().getTime() - 60 * 60 * 24 * 3 * 1000).format("YYYY-MM-DD"), dayjs().format("YYYY-MM-DD")]
      },
      tableData: [],
      tableColumns: [{
        type: 'selection',
        title: '勾选',
        align: 'center',
        key: 'checkbox',
        width: 60,
        visible: true
      }, {
        title: "序号",
        align: "center",
        key: "index",
        width: 60,
        slots: {
          customRender: "index"
        },
        visible: true
      }, {
        title: "订单号",
        align: "center",
        key: "orderNo",
        visible: true,
        width: 200
      }, {
        title: "IMIE号",
        align: "center",
        key: "imei",
        visible: true,
        width: 200
      }, {
        title: "手机号",
        align: "center",
        key: "parentPhone",
        visible: true,
        width: 140
      }, {
        title: "所属单位",
        align: "center",
        key: "schoolName",
        visible: true,
        width: 200
      }, {
        title: '设备名',
        align: 'center',
        key: 'objectname',
        visible: true,
        width: 140
      }, {
        title: '员工性别',
        align: 'center',
        key: 'sex',
        slots: {
          customRender: 'sex'
        },
        visible: true,
        width: 140
      }, {
        title: '设备型号',
        align: 'center',
        key: 'deviceType',
        visible: true,
        width: 140
      }, {
        title: '套餐规格',
        align: 'center',
        key: 'mealType',
        visible: true,
        width: 140
      }, {
        title: '套餐价格',
        align: 'center',
        key: 'mealPrice',
        visible: true,
        width: 140
      }, {
        title: '总价',
        align: 'center',
        key: 'totalPrice',
        visible: true,
        width: 140
      }, {
        title: '订单状态',
        align: 'center',
        key: 'payFlag',
        //1.创建订单（支付中），2支付成功，3支付失败，4支付取消5.已发货
        slots: {
          customRender: 'payFlag'
        },
        visible: true,
        width: 140
      }, {
        title: '支付时间',
        align: 'center',
        key: 'payTime',
        visible: true,
        width: 200
      }, {
        title: '操作',
        align: 'center',
        key: 'operate',
        slots: {
          customRender: 'operate'
        },
        visible: true,
        width: 140
      }],
      selectList: [],
      errorList: []
    });
    // 查询数据
    const onSearch = async () => {
      if (refData.searchForm.alarmTime === null) {
        refData.searchForm.alarmTime = ['', ''];
      }
      refData.loading = true;
      const params = {
        deptId: refData.searchForm.deptId,
        schoolId: refData.searchForm.schoolId,
        pageNo: refData.current,
        pageSize: refData.pageSize,
        parentPhone: refData.searchForm.parentPhone,
        imei: refData.searchForm.imei,
        orderNo: refData.searchForm.orderNo,
        payFlag: refData.searchForm.payFlag,
        startTime: refData.searchForm.alarmTime.length > 1 ? `${refData.searchForm.alarmTime[0]} 00:00:00` : "",
        endTime: refData.searchForm.alarmTime.length == 2 ? `${refData.searchForm.alarmTime[1]} 23:59:59` : ""
      };
      try {
        if (params.startTime == ' 00:00:00') throw {
          msg: "开始时间不能为空"
        };
        if (params.endTime == ' 23:59:59') throw {
          msg: "结束时间不能为空"
        };
        if (params.imei && params.imei.length > 20) throw {
          msg: "IMEI号错误，请输入正确IMEI号"
        };
        const {
          msg,
          code,
          data
        } = await API.mpOrderList(params);
        if (code !== 0) throw {
          msg
        };
        refData.total = code === 0 ? data.total : 0;
        refData.tableData = code === 0 ? data.data || [] : [];
        refData.loading = false;
      } catch (error) {
        refData.loading = false;
        message.warning(error.msg);
      }
    };
    // 防抖
    const [debounceOnSearch] = useDebounce(onSearch, 200);
    // 分页
    const currentChange = val => {
      refData.current = val;
      debounceOnSearch();
    };
    // 页码
    const pageSizeChange = val => {
      refData.pageSize = val;
      debounceOnSearch();
    };
    //发货
    const handleShip = async () => {
      const params = {
        ids: refData.selectList,
        orgId: refData.searchForm.deptId
      };
      ElMessageBox.confirm(`是否确认对当前选中的“${refData.selectList.length}”条订单发货`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const {
          code,
          msg
        } = await API.payFlagBatch(params);
        code == 0 && debounceOnSearch();
        message[code == 0 ? 'success' : 'warning'](code == 0 ? '发货成功' : msg);
      }).catch(() => {
        // ElMessage.info("已取消退出");
      });
    };
    //导出
    const handleExport = async () => {
      refData.tableLoading = true;
      const params = {
        deptId: refData.searchForm.deptId,
        schoolId: refData.searchForm.schoolId,
        pageNo: refData.current,
        pageSize: refData.pageSize,
        parentPhone: refData.searchForm.parentPhone,
        imei: refData.searchForm.imei,
        orderNo: refData.searchForm.orderNo,
        payFlag: refData.searchForm.payFlag,
        startTime: refData.searchForm.alarmTime.length > 1 ? `${refData.searchForm.alarmTime[0]} 00:00:00` : "",
        endTime: refData.searchForm.alarmTime.length == 2 ? `${refData.searchForm.alarmTime[1]} 23:59:59` : ""
      };
      await API.mpOrderExport(params);
      refData.tableLoading = false;
    };
    //组织数回调
    const handleClick = data => {
      if (data.type == "org") {
        refData.searchForm.deptId = data.id;
        refData.searchForm.schoolId = null;
      } else {
        refData.searchForm.deptId = data.parentId;
        refData.searchForm.schoolId = data.id;
      }
      debounceOnSearch();
    };
    //勾选table
    const handleSelectionChange = data => {
      refData.selectList = data.map(item => item.id);
    };
    //导入订单列表
    const handleImport = async file => {
      const {
        code,
        msg,
        data
      } = await API.importMpOrder({
        file,
        type: refData.importType ? 1 : 2
      });
      if (code == 0) {
        message.success("上传成功");
        debounceOnSearch();
        refData.importShow = false;
      } else {
        if (data) {
          refData.errorList = data;
          refData.promptBoxRefs.visible = true;
        } else {
          message.warning(msg);
        }
      }
    };
    //打开导入弹框
    const handleShowImport = onoff => {
      refData.importType = onoff;
      refData.importShow = true;
    };
    //绑定订单IMEI
    const handleBingImei = id => {
      refData.refBingImei.id = id;
      refData.refBingImei.visible = true;
    };
    // 初始化函数调用
    return {
      ...toRefs(refData),
      currentChange,
      pageSizeChange,
      debounceOnSearch,
      handleShip,
      handleExport,
      handleClick,
      handleSelectionChange,
      handleImport,
      handleShowImport,
      handleBingImei,
      Search
    };
  }
};